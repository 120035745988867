.middleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
} 

  .icon {
    margin-top: 20px;
    color: #5b9bd4;
    height: 150px;
    width: 150px;
    margin-bottom: 20px;
  }