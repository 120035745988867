.menu-container {
    position: relative;
    font-family: Arial, sans-serif;
  
    .menu-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      border-radius: 4px;
  
      &:focus {
        outline: none;
      }
    }
  
    .menu-items {
      position: absolute;
      min-width: 140px;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 0;
      right: 120%;
      top: 0;
      z-index: 100;
      box-shadow: 0 2px 5px rgba(0,0,0,0.15);

      &.menu-items-bottom {
        bottom: 0;
        top: auto;
      }
  
      button {
        display: block;
        width: 100%;
        padding: 8px 16px;
        border: none;
        background: none;
        text-align: left;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
        font-size: 12px;
  
        &:hover,
        &.active {
          background-color: #f7f7f7;
        }
  
        &:focus {
          outline: none;
        }
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
.delete-button {
  color: red
}
  