$bg-color: #5b9bd4;
$disabled-color: #ccc;

.disabled {
  pointer-events: none;
  color: $disabled-color;
  cursor: not-allowed;
}

.unitText {
  font-size: small;
}

.radioTile {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-auto-rows: 1fr;
  gap: 12px;
  padding: 0px;
  margin: 0px;
}

.radioTile label {
  display: flex;
  align-items: stretch;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  min-height: 55px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #d4d4d4;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 14px;
  color: black;
}

.radioTile input {
  display: none;
}

.radioTile input:checked ~ label {
  background-color: #cadced;
  border: 2px solid #5b9bd4;
  // box-shadow: 0px 0px px 0px #8e8e8e;
  // color: #5b9bd4;
}

.radioTile label:hover {
  background-color: #e0e0e0;
}


.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  top: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

