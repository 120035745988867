.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dfecf7;
  height: 64px;
  padding: 0 24px 0 32px;
  // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.logo {
  cursor: pointer;
  // width: 169px;
  // height: 28px;
  // max-width: 50%;
  // max-height: 50%;
  width: auto;
  max-height: 50%;
}

.companyLogo{
  // width: 150px;
  height: 35px;
}

.logosContainer {
  height: 64px;
  display: flex;
  align-items: center;

  // Add a margin to the left of every child except the first one
  > *:not(:first-child) {
    margin-left: 22px;
  }
}

.bellIconButton,
.userPhotoButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.dropdownContainer {
  position: relative;
  
  .menuItems {
    box-shadow: 0px 0px 6px 1px #8fa9b5;
    position: absolute;
    margin-top: 5px;
    left: 50%;
    transform: translateX(-50%);
    // border: 1px solid #ddd;
    background-color: white;
    border-radius: 3px;
    
    button {
      border-radius: 3px;
      display: block;
      width: 80px;
      padding: 10px 0;
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      background: none;
      border: none;
  
      &:hover,
      &.active {
        background-color: #ebf2f8;
      }
  
      &:focus {
        outline: none;
      }
    }
  }
}


.userName {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  color: white;
  text-align: center;
  background-color: #d13674;
}

.userInfo {
  display: flex;
  align-items: center;

  // Add a margin to the right of every child except the last one
  > *:not(:last-child) {
    margin-right: 24px;
  }
}

.userPhoto {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.bellIcon {
  width: 24px;
  height: 24px;
}
