$bg-color: #5b9bd4;
$disabled-color: #ccc;

.formGroup {
  display: flex;
  flex-direction: column;
  // margin-bottom: 8px;

  &[hidden] {
    margin: 0;
    padding: 0;
    display: none;
  }
}

.error {
  input {
    border-color: red !important;
  }
  select {
    border-color: red !important;
  }

  label {
    color: red !important;
  }
}

.errorMessage {
  color: red;
  font-size: xx-small;
  text-align: end;
  margin: 0px 10px 3px;
}
