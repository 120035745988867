@import '../../styles/colors';

.campaignSummary {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.summarySection {
  border: 1px solid $divider-color;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  gap: 20px;
  width: 100%;
}

.divider {
  background-color: $divider-color;
  width: 1px;
  height: flex;
}

hr {
  border: none;
  height: 1px;
  background-color: $divider-color;
}

.summaryTitle {
  font-weight: bold;
  width: 100px;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.editIcon {
  // color: $primary-color;
  margin-left: 10px;
  font-size: 0.875rem;
}

.summaryFields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 25px;
  width: 100%;
}

.summaryGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.summaryFieldLabel {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $primary-color;
}
