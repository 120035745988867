.heading {
    color: #5b9bd4;
    font-size: xxx-large;
    // margin-bottom: 5px;
    font-weight: 150;
}

.errorContent {
    flex: 1;
    text-align: left;
}

.image {
    flex: 1;
    max-width: 100px;
    object-fit: contain;
    text-align: center;

    a {
        color: #5b9bd4;
        transition: color 0.3s ease, color 0.3s ease;
        text-decoration: none;
        font-size: small;
        // font-size: 50px;
    }

    a:hover {
        color: gray;
    }

    img {
        width: 100%;
    }
}


.container {
    width: 80%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-between;
    // margin: -100px auto 0px auto;
    font-size: 24px;
    padding-top: 150px;
    padding-left: 200px;
    // margin-top: -200px;
}

.background {
    height: 100vh;
    background-image: url("../../assets/images/login-bg-flying-paper-planes.png");
    background-repeat: repeat;
    justify-content: left;
    // align-items: center;
    display: flex;
}


.logo {
    width: 30px;
    height: auto;
    margin-left: 8px;
    vertical-align: middle; 
  }
  
  .link p {
    margin: 0; 
  }

  .underline {
    align-items: center;
    text-decoration: none;
    color: inherit;
    display: inline-block;
    border-bottom: 2px solid currentColor; 
    padding-bottom: 0px;
    font-size: 16px;
  }