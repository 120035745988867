@import './colors';

@mixin form-fields {
  label {
		font-size: medium;
		font-weight: bold;
    margin: 2px 0px;
	}

  input {
    font-size: medium;
    padding: 3px 0px;
    border: 1px solid $divider-color;
    border-radius: 5px;
    margin: 0px;
    padding: 6px 8px;

    &:focus {
      outline: none;
      // background-color: none;
      border-color: $primary-color !important;
    }
    &::placeholder {
      color: #adadad;
      font-size: 14px;
    }
    &[type="text"],
    &[type="password"],
    &[type="date"] {
      border: 1px solid $divider-color;
      border-radius: 5px;
      width: 100%;
    }

    &[type="number"] {
      border: 1px solid $divider-color;
      border-radius: 5px;
      // width: min-content;
      width: 100%;
      max-width: none;
    }
  }

  fieldset {
    border: none;
  }

  select {
    outline: none;
    font-size: medium;
    padding: 8px;
    border: 1px solid $divider-color;
    border-radius: 5px;
    // text-indent: 5px;
    &:focus {
      outline: none;
      border: 1px solid $primary-color !important;
    }
    // padding-left: 5px;
    width: 100%;
    background: none;
    // border-bottom: 1px solid $divider-color;
    // appearance: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome- https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="%235b9bd4"/></svg>');
    background-repeat: no-repeat; 
    background-position: calc(100% - 8px) center;;
  }
}
