* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body, html, #root {
  height: 100%;
  font-family: Roboto, sans-serif;
}

body {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  min-width: 280px;
}

body > div {
  width: 100%;
  /* height: calc(var(--vh, 1vh) * 100); */
  height: 100vh;
}
