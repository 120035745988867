.redirectLink {
  text-decoration: none;
  font-size: 14px;
  color: #959595;
  margin-top: 30px;

  &:hover {
    text-decoration: underline;
  }
}
