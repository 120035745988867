.sidebar {
  width: 70px;
  min-width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;
  gap: 25px;
  border-right: 1px solid #d9e4e7;
  background-color: #fbfbfb;
  text-decoration: none;
  color: inherit;
}

.footerText {
  position: fixed;
  bottom: 0;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  color: grey;
}

.menuItem {
  position: relative;
  display: flex;
  flex-direction: column;
}

.selected {
  color: #5b9bd4;
  // color: #d13674;
}

.notSelected {
  color: #b7cbdc;
  transition: color 0.2s;
}

.notSelected:hover {
  color: #8fbbe2;
}

.icon {
  text-align: center;
  justify-content: center;
  align-items: center;
  // cursor: pointer;
}

.menuItem ~ label {
  text-decoration: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s;
  cursor: auto;
  font-size: x-small;
}

.menuItem:hover ~ label {
  visibility: visible;
  opacity: 1;
  color: #6d9fca;
}

