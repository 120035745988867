.modalMultipartForm {
  display: none;
}

.stepIndicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  width: 100%;
}

.step {
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;

  &:not(:first-child) {
    padding-left: 5px;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    display: inline;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 1px;
    background: #5b9bd4;
    // background: green;
    left: 100%; // Start from the right edge of the element
    width: 100%;
  }
}
.greyLine:not(:last-child)::after{
  background: #959595;
}

.stepNumber {
  position: absolute;
  padding: 0.5em;
  color: white; // Change color as needed
}

.stepLabel {
  margin: 5px;
  background-color: white;
}