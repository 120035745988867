.actionButton {
  cursor: pointer;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #5b9bd4;
  border-radius: 3px;
  
    &:hover {
      background-color: darken(#5b9bd4, 10%);
    }
}


.primary {
  width: 100%;
  padding: 15px 80px;
  margin-top: 30px;
}

.secondary {
  padding: 13px 50px;

  &:disabled {
      background-color: darken(#5b9bd4, 20%);
      cursor: not-allowed;
    }
}
