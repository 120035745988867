.slider { 
  position : relative ;
  display : inline-block;
  width : 40px;
  height : 20px;
  background-color: #ccc;
  border-radius: 20px;
}

.slider::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

.switch:checked + .slider::after {
  left : 21px; 
}
.switch:checked + .slider {
  background-color: #2e83ce;
}

.switch {
  display: none;
}
