$bg-color: #5b9bd4;

p {
  margin: 5px 0px;
  
  &.data {
    font-size: small;
  };
}



.summaryGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  // margin-top: 20px;
  margin-left: 10px;

  hr {
    grid-column: 1 / -1; /* Span across all columns */
    border: 0;
    height: 1px;
    background-color: #ccc;
  }
}

.record {
  display: inline-flex;
  flex-direction: column;

  label {
    margin: 0px;
    padding: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: right; /* Adjust as needed for alignment */
  gap: 10px; /* Space between buttons */
}

.formButton {
  padding: 10px;
  border: none;
  background-color: transparent;
  color: $bg-color;
  border-radius: 0px;
  cursor: pointer;
}
