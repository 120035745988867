@import '../../styles/layout';

$header-padding: 20px 20px 10px;
$button-bg-color: #5b9bd4;

.uploadContainer {
    display: flex;
    height: 100%;
}

.uploadContainer.middleSeparator::before {
    content: '';
    position: relative;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: black;
  }

.table, .uploadButton {
    flex: 1;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    text-align: center;
    flex-direction: column;

    nav {
        justify-content: center;
    }
}

.uploadButton {
    justify-content: flex-start;

    h2 {
        margin-top: 20px;
        padding: 14vh 8vw;
        border: 3px dotted grey;
        cursor: pointer;
        background-color: rgb(245, 241, 241);
        line-height: 1.3;
    }

    span {
        text-decoration: underline;
    }

    h4 {
        margin-top: 10px;
    }

    ul {
        height: 25vh;
        overflow-y: auto;

        li {
            margin-top: 5px;
        }

        button {
            background: none;
            border: none;
            margin-left: 10px;
            cursor: pointer;
        }
    }
}

.backButton {
    color: #5b9bd4;
    text-decoration: none;
    text-decoration: underline;
}

.backButton:hover {
    color: #567d9e;
    text-decoration: none;
    text-decoration: underline;
}


.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    // padding: $header-padding;
}

.mainContent {
    @include page-content;
}

.heading {
    color: $button-bg-color;
}
