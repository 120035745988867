.fieldsContainer {
  display: flex;
  gap: 10px;
}

.unitContainer {
  font-size: small;
  margin-left: 10px;
}

.numberContainer {
  width: 50px;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
