@import '../../styles/colors';
@import '../../styles/layout';
@import '../../styles/form';

.tabLayout {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  overflow: auto;
}

.tabsContainer {
  display: inline-flex;
  flex-direction: row;
  gap: 30px;
  // margin-bottom: 10px;
  padding: 0px 25px;
  border-bottom: 1px solid $divider-color;
  box-shadow: 0 0 2px 1px rgba($divider-color, 0.2);
}

.tab {
  color: $text-color-light;
  font-weight: bold;
  padding: 10px 5px;
  // border-radius: 20px;
  font-size: small;
  transition: border 100ms;
}

.activeTab {
  color: $text-color;
  // background-color: $text-color;
  // border: 1px solid $divider-color;
  // box-shadow: 0px 0px 1px 0px $divider-color;
  border-bottom: 3px solid $primary-color;
  margin-bottom: -0.5px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.heading {
  @include page-content;
}

.tabContent {
  @include page-content;
  flex: 1;
  overflow: auto;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.choiceButton {
  border: 2px solid $divider-color;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  gap: 8px;
  align-items: center;
  transition: border-color 200ms, box-shadow 200ms;
}

.selected {
  border-color: $primary-color;
  box-shadow: 0 0 2px 1px rgba($primary-color, 0.2);
  color: $text-color;
}

.textBoxContainer {
  flex-grow: 1;
}

.buttonsContainer {
  margin-left: auto;
  // margin-right: 15px;
  font-size: medium;
}

.inputContainer {
  width: 400px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: start;
}

.title {
  display: flex;
  flex-direction: row;
  // justify-content: flex-end;
  margin: 0px 0px 10px 0px;
  padding: 0px;
}

.blacklistFilter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border: 2px solid $divider-color;
  width: fit-content;
  padding: 10px 5px 10px 10px;
  border-radius: 5px;

  .filterIcon {
    margin-right: 10px;
  }
  
  label {
    display: flex;
    font-size: medium;
    color: $text-color;
    padding: 0px;
    margin: 0px;
    align-items: center;
  }

  select {
    font-size: medium;
    color: $text-color;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    border: none;
    &:focus {
      outline: none;
    }
  }
}

.closeButton {
  margin: 0px;
  padding: 0px;
  color: $divider-color;
  font-size: medium;
  text-align: end;
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

.primaryButton {
  background-color: $primary-color;
  border: none;
  color: white;
  padding: 8px 10px;
  border-radius: 5px;
  transition: filter 200ms;

  &:hover {
    filter: brightness(0.95);
    cursor: pointer;
  }
}

.radioButton fieldset {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  label {
    border: 2px solid $divider-color;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    gap: 8px;
    align-items: center;
    transition: border-color 200ms, box-shadow 200ms;
    font-size: medium;
    color: $text-color;
    margin: 0px;
    font-weight: normal;
  }

  input {
    display: none;
  }

  input:checked ~ label {
    border-color: $primary-color;
    box-shadow: 0 0 2px 1px rgba($primary-color, 0.2);
    color: $text-color;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
  @include form-fields;
}

