$bg-color: #5b9bd4;

.checkboxTick {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 5px 0px 5px 10px;
  width: 16px;
  height: 16px;
  outline: none;
  border: none;

  &:before {
    content: "\2713"; /* Unicode character for the tick symbol */
    position: relative;
    top: -8px;
    left: 13px;
    font-size: 16px;
    font-weight: 700;
    color: $bg-color;
    visibility: hidden;
  }
  &:checked::before {
    visibility: visible;
  }
}

.infoIcon {
  cursor: pointer;
  margin: 0 10px;
}
