
@import '../../styles/colors';
@import '../../styles/form';

.mainContainer {
	padding: 20px 50px;
	display: flex;
	flex-direction: row;
  overflow: auto;
  box-sizing: border-box;
}

.stepsContainer {
	display: flex;
	flex-direction: column;
}

.stepContent {
	display: flex;
	margin: 2px 0px;
  align-items: center;
  // flex-direction: column; // Stack items vertically
  // margin: 2px 0px;
}

.clickable {
  cursor: pointer;
}

.stepLabel {
	margin: 0px 10px;
	color: $text-color-light;
  white-space: nowrap;
}

.stepIcon {
	font-size: 20px;
	color: #474c52;
}

.stepLine {
	height: 18px;
	width: 2px;
	background-color: $divider-color;
	margin: 0 9px;
	border-radius: 10px;
}

.flexContainer {
	display: flex;
}

.contentContainer {
	margin-top: 20px;
	gap: 80px;
	display: flex;
	width: 100%;
}

.buttonContainer {
	display: flex;
  margin-left: auto;
  gap: 10px;
  justify-content: end;
}

.primaryButton {
  background-color: $primary-color;
  color: white;
  border-radius: 20px;
  padding: 6px 20px;
  border: none;
  transition: filter 200ms;
  
  &:hover {
    filter: brightness(0.85);
  }
}

.secondaryButton {
  color: $primary-color;
  border: none;
  border-radius: 20px;
  padding: 6px 20px;
  background-color: $background-color;
  border: 1px solid $primary-color;
  transition: filter 200ms;

  &:hover {
    filter: brightness(0.85);
  }
}

.formContainer {
  flex: 0.85;
	// width: 100%;
	font-size: medium;
}

.disabled {
  pointer-events: none;
}

.campaignControl {
  padding: 5px 5px 10px;
  margin-bottom: 5px;
  border-radius: 10px;
  // max-width: 600px;
  transition: background-color 200ms;
  // border: 1px solid $divider-color;
  &:hover {
    background-color: #eaeaea;
  }
}

.switchContainer {
  justify-self: flex-end;
  margin-left: auto;
}

.contentHeader {
  display: flex;
  align-items: center;
  // padding: 0px 0px 2px;
  border-bottom: 1px solid $divider-color;
  margin-bottom: 10px;
}

.content {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  width: 100%;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 10px;
}

.numberContainer {
  width: 60px;
}

.longNumberContainer {
  width: 85px;
}

.targetDatesContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.targetDate {
  width: 200px;
}

.completedIcon {
  color: $primary-color;
}

.currentIcon {
  color: $primary-color;
}

.notCompletedIcon {
  color: $primary-color;
}

.error {
  font-size: small;
  color: $error;
}

.formContent {
  margin: 10px 0px;
  @include form-fields;
}
