.errorMessages {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 23px;
}

.errorMessage {
  color: red;
  margin: 3px 0;
  font-size: 12px;
  text-align: left;
  white-space: pre-line;
}
