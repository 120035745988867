@import '../../styles/colors';

.listComponentContainer {
  display: flex;
  flex-direction: column;
}

.searchBarContainer {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  // padding: 0px 15px;
}

.tableWrapper {
  overflow: auto;
  margin-top: 5px;
  height: 70vh;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $divider-color;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($divider-color, 8%);
  }

  :global {
    .rdt_TableHead {
      color: $text-color;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      border-bottom: 1px solid $divider-color;
    }
  
    .rdt_TableRow {
      color: $text-color;
      border-color: $divider-color;
      font-size: 0.9rem;
      &:hover {
        background-color: #eef3f8;
      }
    }
  }
}

.searchBar {
  border: 1px solid #5b9bd4;
  border-radius: 20px;
  padding: 5px 10px;
  input {
    border: none;
    padding-left: 10px;
    &:focus {
      outline: none;
    }
  }
}

.fullSize {
  margin: 0px 15px;
  .searchBar {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      border: none;
    }
  }
}