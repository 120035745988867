@import '../../styles/layout';

.mainContainer {
  @include page-content;
  width: 100%;
  // height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
