@import '../../styles/colors';

.radioTile fieldset {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 2fr));
  // grid-auto-columns: 1fr;
  grid-template-rows: repeat(auto-fit, minmax(50px, 1fr));
  // grid-auto-rows: 1fr;
  gap: 12px;
  padding: 0px;
  margin: 0px;
  border: none;
}

.radioTile fieldset label {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  /* word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; */
  
  // align-items: stretch;
  height: 100%;
  //align-items: center;
  // justify-content: center;
  // text-align: center;
  background-color: white;
  min-height: 55px;
  padding: 12px 12px;
  border-radius: 3px;
  border: 1px solid #d4d4d4;
  transition: background-color 0.3s ease, color 0.3s ease;
  // font-size: 14px;
  color: black;
}

.radioTile fieldset input {
  display: none;
}

.radioTile fieldset input:checked ~ label {
  background-color: #cadced;
  border: 2px solid #5b9bd4;
  // box-shadow: 0px 0px px 0px #8e8e8e;
  // color: #5b9bd4;
}

.radioTile fieldset label:hover {
  background-color: #e0e0e0;
}

.description {
  margin-top: 5px;
  word-break: break-word;
  font-weight: normal;
  color: $text-color-light;
  font-size: 0.95rem;
}

.labelContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.title {
  font-weight: bold;
  font-size: medium;
  color: $text-color;
}

.iconContainer {
  margin: 3px 0px 0px;

  img {
    vertical-align: top;
    margin: 0.1rem;
  }
}
