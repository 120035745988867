$bg-color: #5b9bd4;
$disabled-color: #ccc;

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // max-width: 400px;
  margin: 0px;
}

.form label {
  margin: 5px 0px;
  // margin: 5px 10px 0px;
  color: rgb(65, 63, 63);
  font-size: x-small;
}

.form input {
  padding: 3px 0px;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
  border-radius: 4px;
  margin: 0px;

  &:focus {
    outline: none;
    background-color: none;
    border-color: $bg-color !important;
  }
  &[type="text"],
  &[type="password"],
  &[type="date"] {
    border-width: 0 0 1px;
    border-radius: 0;
    width: 96%
  }

  &[type="number"] {
    border-width: 0 0 1px;
    border-radius: 0;
    max-width: 60px;
  }

  &[type="radio"] {
    margin: 8px 10px 8px 0px;
  }
}

.form input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 5px 0px;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  outline: none;
  
  &:checked { 
    background-color: $bg-color;
  }
  &:before {
    content: "\2713"; /* Unicode character for the tick symbol */
    position: relative;
    top: -4px;
    left: 3px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    visibility: hidden;
  }
  &:checked::before {
    visibility: visible;
  }
}

.form select {
  padding: 0px 0px 3px;
  margin: 0px;
  border: 0px;
  outline: 0px;
  // text-indent: 5px;
  &:focus {
    border: 1px;
  }
  // padding-left: 5px;
  width: 96%;
  border-bottom: 1px solid #ccc;
  appearance: none;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><!--!Font Awesome Free 6.5.1 by @fontawesome- https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="%235b9bd4"/></svg>');
  background-repeat: no-repeat; 
  background-position: right;
}

.form select:focus {
  border-bottom: 1px solid $bg-color !important;
  outline: none;
}
