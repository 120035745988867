@import '../../styles/colors';

.connectionSection {
  display: flex;
  flex-direction: row;
  width: 95%;
  padding: 15px 25px;
  border: 1px solid $divider-color;
  border-radius: 10px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
}

.imageContainer {
  // height: 50px;
  width: 50px;
  img {
    // height: 50px;
    width: 50px;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .title {
    font-weight: 700;
  }

  .description {
    color: $text-color-light;
  }
}

.rightContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 20px;
  align-items: center;

  a {
    color: $primary-color;
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    // flex-direction: column;
    margin-left: 0px;
  }
}

.buttonContainer {
  button {
    background-color: $primary-color;
    border: none;
    padding: 10px;
    border-radius: 4px;
    color: white;
    box-sizing: border-box;
    width: 90px;
    transition: background-color 100ms ease-in-out;

    &:hover {
      background-color: $accent-2;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}

