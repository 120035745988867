.container {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/images/login-bg-flying-paper-planes.png");
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 18px;

  .box {
    width: 344px;
    padding: 32px 46px 40px;
    border-radius: 4px;
    border: solid 1px #d4d4d4;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    text-align: center;

    .title {
      margin-bottom: 40px;
      font-size: 20px;
      font-weight: 600;
      color: #5b9bd4;
    }
  }

  .logo {
    object-fit: contain;
    margin-bottom: 27px;
    width: 250px;
  }
}
