@import '../../styles/colors';

.removeIcon {
  color: $divider-color;
  transition: color 200ms;
  &:hover {
    color: $error;
  }
  &:active {
    color: $error;
  }
}

.highlightedText {
  color: $primary-color;
}

.buttonsContainer {
  display: flex;
  justify-self: end;
  margin-top: 10px;
  gap: 30px;

  button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: $primary-color;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }
}

.title {
  margin-bottom: 10px;
}
