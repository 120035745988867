@import '../../styles/layout';

.iframeEmbedding {
  width: 100%;
  height: 100%;
  border: none;
}

.container {
  @include page-content;
  display: flex;
  flex-direction: column;
  height: 100%
}
